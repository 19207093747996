<template>
  <div>
    <!-- Table search bar and selection -->

    <div class="flex gap-3">
      <svg
        @click="(selectedAll = !selectedAll), handleSelectAll(selectedAll)"
        v-if="selectAll"
        class="h-7 w-7 cursor-pointer"
        viewBox="0 0 16 16"
        :fill="selectedAll ? '#0D69D5' : 'grey'"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="1">
          <g clip-path="url(#clip0_4665_6289)">
            <path
              d="M9.04766 12.0447H3.01553C1.35291 12.0447 0 10.6941 0 9.0338V3.01091C0 1.35084 1.35291 0 3.01553 0H9.04739C10.7103 0 12.0629 1.35084 12.0629 3.01091V9.03354C12.0629 10.6939 10.71 12.0444 9.04739 12.0444L9.04766 12.0447ZM3.01553 1.10734C1.96422 1.10734 1.10904 1.96121 1.10904 3.01091V9.03354C1.10904 10.0832 1.96422 10.9371 3.01553 10.9371H9.04739C10.0987 10.9371 10.9539 10.0832 10.9539 9.03354V3.01091C10.9539 1.96121 10.0987 1.10734 9.04739 1.10734H3.01553Z"
              fill="current-color"
            />
            <path
              d="M9.49067 13.9157H3.2214C2.91521 13.9157 2.66675 13.6679 2.66675 13.3619C2.66675 13.0559 2.91494 12.8081 3.2214 12.8081H9.4904C11.3284 12.8081 12.8235 11.3153 12.8235 9.48019V3.2208C12.8235 2.91507 13.0716 2.66699 13.3781 2.66699C13.6846 2.66699 13.9328 2.9148 13.9328 3.2208V9.48019C13.9328 11.926 11.9399 13.9155 9.49067 13.9155V13.9157Z"
              fill="current-color"
            />
            <path
              d="M10.129 16.028H5.88791C5.58171 16.028 5.33325 15.7801 5.33325 15.4742C5.33325 15.1682 5.58144 14.9203 5.88791 14.9203H10.1287C12.7793 14.9203 14.9356 12.7674 14.9356 10.1209V5.88681C14.9356 5.58109 15.1838 5.33301 15.4902 5.33301C15.7967 5.33301 16.0449 5.58082 16.0449 5.88681V10.1212C16.0449 13.3783 13.3911 16.028 10.129 16.028V16.028Z"
              fill="current-color"
            />
            <path
              d="M9.28787 3.71789C9.07124 3.50159 8.72026 3.50159 8.50364 3.71789L4.91673 7.2993L3.61345 5.99802C3.39682 5.78172 3.04585 5.78172 2.82922 5.99802C2.61259 6.21432 2.61259 6.56475 2.82922 6.78105L4.45568 8.40502C4.46728 8.41661 4.47969 8.42738 4.49183 8.43789C4.50235 8.45028 4.51288 8.4624 4.52475 8.47398C4.63292 8.58199 4.77483 8.63614 4.91673 8.63614C5.05863 8.63614 5.20053 8.58199 5.30871 8.47398L9.28787 4.50092C9.5045 4.28462 9.5045 3.93418 9.28787 3.71789Z"
              fill="current-color"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_4665_6289">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <span v-if="selectAll" class="w-24 mt-1"> Select all</span>
      <input
        type="text"
        placeholder="Search"
        class="input input-bordered input-sm w-full"
        v-model="searchedValue"
        @input="handleSearch(searchedValue)"
        v-if="searchBar"
      />
    </div>

    <!-- Table layout-->
    <div class="w-full overflow-hidden rounded-lg bg-white border-2 mt-5">
      <div class="overflow-auto max-h-96">
        <table class="min-w-full bg-white" id="dataTable">
          <thead>
            <tr class="border-b">
              <th
                :class="customClass?.headers"
                v-for="column in getColumns"
                :key="column.name"
              >
                {{ column.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template>
              <tr v-for="(data, dtaIndx) in rows" :key="data.name+'_'+dtaIndx" class="tableRows">
                <td
                  v-for="result in columns"
                  :key="result.label"
                  class="text-center border-b"
                >
                  <span v-if="getColumnType(result.label) == 'text'">{{
                    data[result.label]
                  }}</span>
                  <slot :name="result.label" :item="data" class="border-b" />
                </td>
              </tr>
            </template>
          </tbody>
          <!-- -->
        </table>
        <div class="flex justify-center">
          <h1 class="p-10 italic font-bold" v-if="rows.length == 0">
            {{ emptyTableMessage }}
          </h1>
        </div>
      </div>
    </div>
    <div class="grid gap-2 mt-2 float-right mb-2">
      <!-- subtotal comp -->
      <div
        v-if="rows.length > 0 && showSubTotal"
        class="shadow-md relative rounded-2xl bg-gray-100 p-2"
      >
        <div class="flex gap-12">
          <div class="grid gap-2">
            <div v-for="data in subTotalFields" :key="data.name">
              <span
                v-if="data.name != 'SUB TOTAL'"
                class="text-sm font-semibold"
              >
                {{ data.name + " %" }}
              </span>
              <span v-else class="text-sm font-semibold">
                {{ data.name }}
              </span>
            </div>
          </div>
          <div class="grid gap-2">
            <div v-for="(data, index) in subTotalFields" :key="data.name">
              <span
                v-if="
                  data.name != 'SUB TOTAL' && data.name != 'Corporate Discount'
                "
                class="text-sm font-semibold"
              >
                {{ data.percent + "%" }}
              </span>
              <span
                class="text-sm font-semibold"
                v-else-if="data.name != 'Corporate Discount'"
                >{{ data.percent }}</span
              >
              <input
                class="w-10 text-center no-spinner"
                :class="{'disableInput': (handleInputDisable || handleDiscountDisable)}"
                v-if="data.name == 'Corporate Discount'"
                v-model="data.percent"
                type="number"
                min="0"
                :disabled="handleInputDisable || handleDiscountDisable"
                @input="(event) => onInputChange(event, index, data)"
                @keypress="isNumber"
              />
            </div>
          </div>
          <div class="grid gap-2">
            <div v-for="data in subTotalFields" :key="data.name" class="flex justify-end">
              <span class="text-sm font-semibold"><span>{{ currencySymbol }}</span> {{ data.value }} </span>
            </div>
          </div>
        </div>
        <hr class="h-px bg-gray-200 border-0 dark:bg-gray-700 mt-3" />
        <div class="flex justify-between mt-1">
          <span class="text-sm font-semibold">Total</span>
          <span class="text-sm font-semibold"> </span>
          <span class="text-sm font-semibold"><span>{{ currencySymbol }}</span> {{ totalAmount }} </span>
        </div>
      </div>
      <!-- -->
    </div>
    <div v-if="orderTable" class="pb-2 float-left">
      <div class="mt-3"></div>
      <div class="mt-5"><b>Notes</b></div>
      <div class="mt-2">
        <textarea
          type="text"
          placeholder="Enter any notes to be displayed in your transaction"
          class="input input-bordered input-sm w-full notesDiv"
          v-model="notes"
          :disabled="disabledNote"
          @input="onInputEditor"
        >
        </textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { isNumber } from "../../plugins/functions";

export default {
  name: "customTable",
  data: () => {
    return {
      note: "",
      discount: 0,
      savedData: null,
      selectedAll: false,
      searchedValue: null,
    };
  },
  components: {},
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    orderTable: {
      type: Boolean,
      default: false,
    },
    notes: {
      type: String,
      default: () => "",
    },
    rows: {
      type: Array,
      default: () => [],
    },
    showSubTotal: {
      type: Boolean,
      default: false,
    },
    searchBar: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    subTotalFields: {
        type: Array,
        default: () => []
    },
    customClass: {
      type: Object,
      default: () => {},
    },
    totalAmount: {
      type: Number,
      default: 0, 
    },
    currencySymbol: {
      type: String,
      default: () => ''
    },
    emptyTableMessage: {
      type: String,
      default: ''
    },
    handleSelectAll: {
      type: Function,
      default: () => {},
    },
    disabledNote: {
      required: false,
      type: Boolean,
      default:  false,
    },
    userType: {
      type: String,
      default: () => ''
    },
    handleInputDisable: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    getColumns: {
      get() {
        const remove_columns = ["currency", "selected"];
        return this.columns.filter((res) => !remove_columns.includes(res.name));
      },
    },
    handleDiscountDisable() {
      if (this.userType == 'tenant_user') return true;
      else return false;
    }
  },
  mounted() {
    this.note = this.notes;
  },
  methods: {
    isNumber,
    onInputChange(event, index, data) {
      if (event.target.value > 100) {
        data.percent = data.validPercent
      }
      else if (event.target.value <= 100) {
        data.validPercent = data.percent
        this.$emit("discountchange", event.target.value, index);
      }
    },
    onInputEditor(event) {
      this.$emit("noteEditor", event.target.value);
    },
    getObjectsList(obj) {
      return Object.entries(obj);
    },
    getColumnType(key) {
      const data = this.columns.filter((res) => res.label == key);
      return data[0].type;
    },
    handleSearch(searchedText) {
      let value = searchedText.toLowerCase();
      let table = document.getElementById("dataTable");
      let rows = table.getElementsByClassName("tableRows");
      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        let cells = row.getElementsByTagName("td");
        let found = false;
        for (let i = 0; i < cells.length; i++) {
          let cell = cells[i];
          if (cell.innerHTML.toLowerCase().indexOf(value) > -1) {
            found = true;
            break;
          }
        }
        if (found) {
          row.style.display = "";
        } else {
          row.style.display = "none";
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
tr:nth-child(even) {
  background-color: #f6f6f6;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f6f6f6;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 3px solid grey;
}

/* CSS to remove the spinner arrows from number input */
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
}

.notesDiv {
  height: 165px;
  width: 490px;
}
::v-deep {
  .disableInput {
    background: #f9fafb !important;
    cursor: not-allowed;
  }
}
</style>
