import axios from "@/axios";

export async function getOrdersList(params) {
  const url = `/tenant-orders?${params}`;
  return await axios.get(url);
}
export async function getOrdersDetails(order_id) {
  const url = `/tenant-orders/${order_id}/details`;
  return await axios.get(url);
}

export async function getOrderCreditPacks(order_id) {
  const url = `/tenant-orders/${order_id}/credit-packs`;
  return await axios.get(url);
}
export async function getTenantInfo(id) {
  const url = `/tenant/${id}`;
  return await axios.get(url);
}
export async function getOrderId() {
  const url = `/tenant-orders/order-number`;
  return await axios.get(url);
}
export async function updateOrder(payload, orderId) {
  const url = `/tenant-orders/${orderId}`;
  return await axios.put(url, payload);
}
export async function createOrder(payload) {
  const url = "/tenant-orders";
  return await axios.post(url, payload);
}
export async function getTenantTax(tenantId) {
  const url = `/tax/tax-tenant/${tenantId}`;
  return await axios.get(url);
}
export async function getTenantCreditPacks(tenantId) {
  const url = `/order-management/tenant/credit-packs?tenant_id=${tenantId}`;
  return await axios.get(url);
}

export async function getCurrencyDetail(currency_id) {
  const url = `/currency/${currency_id}`;
  return await axios.get(url);
}
